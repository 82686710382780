















































































































import Vue from 'vue';

import gql from 'graphql-tag';
import data from '../seedcarddata.json';
import { setTimeout } from 'timers';

export default Vue.extend({
  name: 'sls-admin-home',
  data() {
    return {
      preventTrackingActive: false,
      featured: false,
      cards: [],
      edit: {},
      showEdit: false,
      createIndex: 0,
    };
  },
  computed: {
    // ironcladCards() {
    //   const ironclad = this.cards.filter((card) => card.character === 'ironclad');
    //   return ironclad.sort(this.sortCardAlphabtically)
    // },
    // silentCards() {
    //   const ironclad = this.cards.filter((card) => card.character === 'silent');
    //   return ironclad.sort(this.sortCardAlphabtically)
    // },
    // defectCards() {
    //   const ironclad = this.cards.filter((card) => card.character === 'defect');
    //   return ironclad.sort(this.sortCardAlphabtically)
    // },
    // colorlessCards() {
    //   const ironclad = this.cards.filter((card) => card.character === 'colorless');
    //   return ironclad.sort(this.sortCardAlphabtically)
    // },
    // curseCards() {
    //   const ironclad = this.cards.filter((card) => card.character === 'curse');
    //   return ironclad.sort(this.sortCardAlphabtically)
    // },
    // editItemData() {
    //   const keys = Object.keys(this.edit);
    //   return keys
    // },
  },
  created() {
    if (localStorage.getItem('isAdmin') === 'true') {
      this.preventTrackingActive = true;
    }
    this.getCards();
  },
  methods: {
    preventGATracking() {
      if (localStorage.getItem('isAdmin') === null) {
        localStorage.setItem('isAdmin', 'true');
        this.preventTrackingActive = true;
      } else {
        localStorage.removeItem('isAdmin');
        this.preventTrackingActive = false;
      }
    },
    async loop() {
      for (const item of data) {
        const res = await this.seed(item);
        console.log(res);
      }
      console.log('Done!');
    },
    async seed(item: any) {
      // for await (const card1 of data) {
      console.log(item);
      // setTimeout(async () => {
      const res = await this.$apollo.mutate({
        mutation: gql`mutation($card: CreateCardInput!) {
          spirespyCreateCard(card: $card) {
            name
            id
          }
        }`,
        // Parameters
        variables: {
          card: item,
        },
      });
      // console.log(res);
      this.createIndex = this.createIndex + 1;
      return res;
      // }, 1000 + (100 * index));
      // console.log(index);

      // }
    },
    editItem(item: any) {
      this.showEdit = true,
      this.edit = item;
    },
    sortCardAlphabtically(a: any, b: any) {
      if (a.name === b.name) {
          return 0;
      } else {
          return (a.name < b.name) ? -1 : 1;
      }
    },
    async getCards() {
      // const itemName = this.itemData.name
      // // if (this.itemType === 'cards') {
      //   const res = await this.$apollo.query({
      //     query: gql`query {
      //       spirespyGetCards {
      //         detailviews
      //         name
      //         id
      //         character
      //         synergies {
      //           name
      //           # id
      //         }
      //         antisynergies {
      //           name
      //           # id
      //         }
      //       }
      //     }`,
      //     // Parameters
      //     variables: {
      //       name: itemName,
      //     },
      //   })
      //   this.cards = res.data.spirespyGetCards;
      //   return
      // // }
      // // relics
      // // console.log('relics');
    },
  },
});
