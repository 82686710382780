import { render, staticRenderFns } from "./adminhome.vue?vue&type=template&id=4c95dac6&scoped=true&"
import script from "./adminhome.vue?vue&type=script&lang=ts&"
export * from "./adminhome.vue?vue&type=script&lang=ts&"
import style0 from "./adminhome.vue?vue&type=style&index=0&id=4c95dac6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c95dac6",
  null
  
)

export default component.exports